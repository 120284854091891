<h1 class="text-center border-bottom pb-3">
  Nieuwe verkooporder voor klant
  <a
    href="{{ indiCustomer.icmLinkID | icmCustomerLink }}"
    target="_blank"
  >
    {{ indiCustomer.customerNumber }}
  </a>
</h1>

<div *ngIf="APIVerkooporder.createdOrderNumber">
  <app-new-salesorder-duplicate-verkooporder
    [verkooporderIds]="APIVerkooporder.createdOrderNumber"
    [amountOfVerkooporders]="ids.length"
  >
  </app-new-salesorder-duplicate-verkooporder>
</div>

<div *ngIf="isLoading">
  <app-loading-gears></app-loading-gears>
</div>

<div
      *ngIf="feedbackSettings.show"
    >
  <div class="feedback_box">
    <app-feedback
      [(settings)]="feedbackSettings"
    ></app-feedback>
  </div>
</div>

<div *ngIf="orderError">
  <app-orderline-errors
    [error]="orderError"
    (closeError)="hideOrderError()"
  ></app-orderline-errors>
</div>

<div [ngClass]="{'d-none': isLoading || feedbackSettings.show || apiResponse || APIVerkooporder.createdOrderNumber }">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
    <app-new-salesorder-orderlines
      [orderlines]="orderlines"
    ></app-new-salesorder-orderlines>

    <div class="d-flex mt-3">
      <div class="w-50">
        <app-new-salesorder-info
          [info]="info"
          [freshdeskTicketNumbers]="ticketIds"
        ></app-new-salesorder-info>
      </div>

      <div class="w-50">
        <app-new-salesorder-shipping-details
          [shippingDetails]="shippingDetails"
          [deliveryAddress]="APIVerkooporder.orderDetails.deliveryAddress"
          [addresses]="shippingAddresses"
          [nightShippingAddress]="nightShippingAddress"
          [hasNightShipping]="indiCustomer.hasNightshipping"
          [hasPickUpAtCounter]="indiCustomer.hasPickUpAtCounter ?? false"
        ></app-new-salesorder-shipping-details>
        <br />
        <app-new-salesorder-invoice-details
          [invoiceDetails]="invoiceDetails"
        ></app-new-salesorder-invoice-details>
      </div>
    </div>

    <div class="mt-1">
      <div>
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="
            !orderlinesComponent?.valid ||
            !shippingComponent?.valid ||
            !invoiceComponent?.valid ||
            !infoComponent?.valid
          "
        >
          <i class="fa-regular fa-shopping-cart fa-lg"></i> Verkooporder aanmaken
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="apiResponse">
  <app-new-salesorder-sent
    [response]="apiResponse"
  ></app-new-salesorder-sent>
</div>