<table class="table">
  <thead>
    <tr>
      <th scope="col" class="width-3 text-center">Kies</th>
      <th scope="col" class="width-3">Ticketnummer</th>
      <th scope="col" class="width-15">Bedrijf</th>
      <th scope="col" class="width-10">Ticketdatum</th>
      <th scope="col" class="width-10">Verwerkingsdatum</th>
      <th scope="col" class="width-10">Ordernummer</th>
      <th scope="col" class="width-10">Agent</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="
      let salesorder of salesorders
        | salesorderSearch : searchText
        | salesorderFilter : selectedDateFilter : selectedErrorFilter : hideProcessedSalesorders
        | filterBySameCustomer : selectedCustomerNumber
        | paginate : { itemsPerPage: 100, currentPage: page }
        ; let i = index
    ">
      <tr *ngIf="!!!salesorder.errorText" class="align-middle" [ngClass]="{ 'offset-rows-color': i % 2 === 0 }">
        <td class="text-center">
          <input
            type="checkbox"
            class="form-check-input"
            [checked]="selectedSalesorderIds.includes(salesorder.id)"
            (change)="onCheckboxChange(salesorder, $event)"
            [disabled]="salesorder.createdOrderNumber"
          />
        </td>

        <td>
          <a
            href="{{ salesorder.ticketId.toString()| freshdeskTicketLink }}"
            target="_blank"
          >
            {{ salesorder.ticketId }}
          </a>
        </td>

        <td>
          <a 
            href="{{ freshdeskServiceUrl + 'icm/redirect/customer?customerNo=' + salesorder.customerNumber }}"
            target="_blank"
          >
            {{ salesorder.companyName }} [{{ salesorder.customerNumber }}]
          </a>
        </td>

        <td>{{ salesorder.createdAt.toString() | cleanDate: 'date-time' }}</td>

        <td *ngIf="salesorder.createdOrderNumber">{{ salesorder.updatedAt | cleanDate: 'date-time' }}</td>
        <td *ngIf="!salesorder.createdOrderNumber">-</td>

        <td *ngIf="salesorder.createdOrderNumber">
          <a
            href="{{ salesorder.createdOrderNumber! | freshdeskOmsOrderLink }}"
            target="_blank"
          >
            {{ salesorder.createdOrderNumber }} 
          </a>
        </td>
        <td *ngIf="!salesorder.createdOrderNumber">-</td>

        <td *ngIf="salesorder.updatedBy">{{ salesorder.updatedBy }}</td>
        <td *ngIf="!salesorder.updatedBy">-</td>
      </tr>
      <tr *ngIf="!!salesorder.errorText" [ngClass]="{ 'offset-rows-color': i % 2 === 0 }">
        <td class="text-center">
          <input
            type="checkbox"
            class="form-check-input border-danger"
            disabled
          />
        </td>
        <td>
          <a
            href="{{ salesorder.ticketId.toString()| freshdeskTicketLink }}"
            target="_blank"
          >
            {{ salesorder.ticketId }}
          </a>
        </td>
        <td *ngIf="salesorder.customerNumber">
          <a 
            href="{{ freshdeskServiceUrl + 'icm/redirect/customer?customerNo=' + salesorder.customerNumber }}"
            target="_blank"
          >
            {{ salesorder.companyName }} [{{ salesorder.customerNumber }}]
          </a>
        </td>
        <td *ngIf="!salesorder.customerNumber">
          -
        </td>
        <td>{{ salesorder.createdAt.toString() | cleanDate: 'date-time' }}</td>
        <td colspan="3">
          <span class="text-danger"><strong>FOUT: </strong></span>{{ salesorder.errorText }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>