import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { OrderDetails } from '../order-details';
import { MancoOrderRequest, MancoTypeOptions } from '../manco-order-request';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { mancoTypeValidator } from './manco-request-validators';
import { NewMancoFormOrderlinesComponent } from './new-manco-form-orderlines/new-manco-form-orderlines.component';
import { NewMancoFormDeliveryaddressComponent } from './new-manco-form-deliveryaddress/new-manco-form-deliveryaddress.component';

@Component({
  selector: 'app-new-manco-form',
  templateUrl: './new-manco-form.component.html',
  styleUrls: ['./new-manco-form.component.scss'],
})
export class NewMancoFormComponent implements OnInit, AfterViewInit {
  @Input() orderDetails!: OrderDetails;
  @Output() submitMancoRequest: EventEmitter<MancoOrderRequest> = new EventEmitter();

  @ViewChild(NewMancoFormOrderlinesComponent)
  orderlinesComponent?: NewMancoFormOrderlinesComponent;
  @ViewChild(NewMancoFormDeliveryaddressComponent)
  deliveryAddressComponent?: NewMancoFormDeliveryaddressComponent;

  mancoTypeOptions = MancoTypeOptions;

  form!: FormGroup;

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.createFormGroup();
  }

  ngAfterViewInit(): void {
    if (this.orderDetails) {
      this.populateFormWithOrderDetails();
      this.setOrderlines();
    }
  }

  createFormGroup(): FormGroup {
    return this.fb.group({
      orderlines: this.fb.array([]),
      mancoType: ['', [Validators.required, mancoTypeValidator()]],
      customerRef: ['', Validators.maxLength(36)],
      note: [''],
      deliveryAddress: this.fb.group({
        company: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        address: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        country: ['', [Validators.required, Validators.pattern(/^(NL|DE)$/)]],
        email: ['', [Validators.required, Validators.email]],
        telephoneNumber: ['', Validators.required],
        switchToDayShipping: [false],
      }),
      ishShippingMethodID: [''],
    });
  }

  populateFormWithOrderDetails(): void {
    const deliveryAddress = this.orderDetails.deliveryAddress || {};

    this.form.patchValue({
      ishShippingMethodID: this.orderDetails.shippingMethod,
      mancoType: '',
      customerRef: this.orderDetails.reference,
      note: '',
      deliveryAddress: {
        company: this.orderDetails.customerDetails.companyName || '',
        firstName: deliveryAddress.firstName || '',
        lastName: deliveryAddress.lastName || '',
        address: deliveryAddress.address || '',
        postalCode: deliveryAddress.postalCode || '',
        city: deliveryAddress.city || '',
        country: deliveryAddress.country || '',
        email: deliveryAddress.emailAddress || '',
        telephoneNumber: deliveryAddress.telephoneNumber || '',
      },
    });
  }

  setOrderlines(): void {
    const orderlinesArray = this.form.get('orderlines') as FormArray;
    if (this.orderDetails.orderlines && this.orderlinesComponent) {
      this.orderDetails.orderlines.forEach((ol, index) => {
        orderlinesArray.push(this.orderlinesComponent?.createOriginalOrderlineGroup(ol, index));
      });
    }
  }

  onSubmit(): void {
    const orderlines = this.orderlinesComponent?.getOrderlines();

    const { switchToDayShipping, ...sanitizedDeliveryAddress } = this.form.value.deliveryAddress;

    const newMancoRequest: MancoOrderRequest = {
      ...this.form.value,
      forCustomerNumber: this.orderDetails.customerDetails.customerNo,
      forOrderNumber: this.orderDetails.orderNumber,
      deliveryAddress: {
        ...sanitizedDeliveryAddress,
      },
      orderlines: orderlines,
    };

    this.submitMancoRequest.emit(newMancoRequest);
  }

  invalidOrderlines(): boolean {
    return !this.orderlinesComponent?.valid;
  }

  resetForm(): void {
    this.orderlinesComponent?.reset();
    this.form.reset();
  }

  get orderlines(): FormArray {
    return this.form.get('orderlines') as FormArray;
  }
}
