import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-new-salesorder-info',
  templateUrl: './new-salesorder-info.component.html',
  styleUrls: ['./new-salesorder-info.component.scss'],
})
export class NewSalesorderInfoComponent implements OnInit {
  @Input() info!: FormGroup;
  @Input() freshdeskTicketNumbers!: number[];
  datePickerMin: string = '';
  datePickerMax: string = '';

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    const today = new Date();

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.datePickerMin = tomorrow.toISOString().split('T')[0];

    const nextYear = new Date(today);
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    this.datePickerMax = nextYear.toISOString().split('T')[0];

    if (!this.info.get('reason')) {
      this.info = this.fb.group({
        reason: ['', Validators.required],
        orderReference: [''],
        note: [''],
        fixedDeliveryDate: [''],
      });
    }
  }

  createInfo(orderReference: string): FormGroup {
    return this.fb.group({
      reason: ['', Validators.required],
      orderReference: [orderReference],
      note: [''],
      fixedDeliveryDate: [new Date()],
    });
  }

  get valid(): boolean {
    return this.info.valid;
  }

  getOrderReference(): string {
    return this.info.getRawValue().orderReference;
  }

  getReason(): string {
    return this.info.getRawValue().reason;
  }

  getAssessmentNotes(): string {
    return this.info.getRawValue().note;
  }

  getFixedDeliveryDate(): Date {
    return this.info.getRawValue().fixedDeliveryDate;
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
