import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Address } from '../../indi-customer';

@Component({
  selector: 'app-new-salesorder-invoice-details',
  templateUrl: './new-salesorder-invoice-details.component.html',
  styleUrls: ['./new-salesorder-invoice-details.component.scss'],
})
export class NewSalesorderInvoiceDetailsComponent implements OnInit {
  @Input() invoiceDetails!: FormGroup;
  invoiceAddress: Address = {
    type: '',
    companyName: '',
    firstName: '',
    lastName: '',
    address: '',
    postalCode: '',
    city: '',
    countryCode: '',
    nightShipping: false,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    if (!this.invoiceDetails.get('emailAddress')) {
      this.invoiceDetails = this.fb.group({
        emailAddress: ['', [Validators.required, Validators.email]],
      });
    }
  }

  createInvoiceDetails(
    invoiceEmailAddress: string,
    invoiceAddress: Address
  ): FormGroup {
    this.invoiceAddress = invoiceAddress;
    return this.fb.group({
      emailAddress: [
        invoiceEmailAddress,
        [Validators.required, Validators.email],
      ],
    });
  }

  get valid(): boolean {
    return !!this.invoiceDetails.get('emailAddress')?.valid;
  }

  getEmailAddress(): string {
    return this.invoiceDetails.getRawValue().emailAddress;
  }

  //TODO extract into directive for use over all components
  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
