import { Component, Input, OnInit } from '@angular/core';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';

@Component({
  selector: 'app-new-salesorder-duplicate-verkooporder',
  templateUrl: './new-salesorder-duplicate-verkooporder.component.html',
  styleUrls: ['./new-salesorder-duplicate-verkooporder.component.scss']
})
export class NewSalesorderDuplicateVerkooporderComponent implements OnInit {
  @Input() verkooporderIds!: string;
  @Input() amountOfVerkooporders!: number;

  feedbackSettings: FeedbackSettings = {
    identifier: 'generalFeedback',
    show: true,
    type: FeedbackType.error,
    message: '',
    buttonText: '',
    closeButton: false,
  };

  ngOnInit(): void {
    this.feedbackSettings.message = this.setFeedbackSettings();
  }

  setFeedbackSettings(): string {
    if (this.amountOfVerkooporders > 1) {
      return 'Voor een of meerdere van deze verkooporders is er al een verkooporder aangemaakt.\nDit zijn de aangemaakte verkooporders: ' + this.verkooporderIds;
    }

    return 'Voor deze verkooporder is er al een verkooporder aangemaakt, namelijk: ' + this.verkooporderIds;
  }
}
