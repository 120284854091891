import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-manco-form-deliveryaddress',
  templateUrl: './new-manco-form-deliveryaddress.component.html',
  styleUrl: './new-manco-form-deliveryaddress.component.scss',
})
export class NewMancoFormDeliveryaddressComponent {
  @Input() shippingMethod!: String;
  @Input() form!: FormGroup;

  isNightShippingMethod(): boolean {
    return this.shippingMethod.toLowerCase().startsWith('shipping-nt');
  }

  onSwitchToDayShippingChange(): void {
    const isChecked = this.form.get(
      'deliveryAddress.switchToDayShipping'
    )?.value;

    this.form.patchValue({
      ishShippingMethodID: isChecked
        ? 'shipping-standard-free'
        : this.shippingMethod,
    });
  }

  get valid(): boolean {
    return !!this.form.get('deliveryAddress')?.valid;
  }

  getFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
