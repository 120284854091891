<div class="d-flex justify-content-center my-4">
  <div class="card w-75 position-relative">
    <button
      type="button"
      class="btn-close position-absolute end-0 m-2"
      aria-label="Close"
      (click)="emitClose()"
    ></button>

    <div class="card-body">
      <h3 class="card-title text-center mb-4">
        <i class="fa-solid fa-boxes-packing me-3 text-success"></i>Het is
        gelukt! Dit is je nieuwe order:

        <a
          class="me-3"
          href="{{ response.orderNumber! | freshdeskOmsOrderLink }}"
          target="_blank"
        >
          <strong>{{ response.orderNumber }}</strong>
        </a>
      </h3>
      <div *ngIf="response.request">
        <div class="row">
          <div class="col-md-6">
            <h5 class="border-bottom pb-2">Order Informatie</h5>
            <p>
              <strong>Originale ordernummer:</strong>
              {{ response.request!.forOrderNumber }}
            </p>
            <p>
              <strong>Klantnummer:</strong>
              {{ response.request!.forCustomerNumber }}
            </p>
            <p>
              <strong>Freshdeskticket</strong>
              <a
                class="link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                href="{{
                  response.request!.freshdeskTicketNumber! | freshdeskTicketLink
                }}"
                target="_blank"
              >
                {{ response.request!.freshdeskTicketNumber }}
              </a>

              <span
                *ngIf="response.freshdeskNoteCreated; else noComment"
                class="ms-3 text-success"
              >
                <i class="fa-solid fa-circle-check me-2"></i>
                Er is een (prive) notitie met order informatie toegevoegd.
              </span>
              <ng-template #noComment>
                <span class="ms-3 text-danger">
                  <i class="fa-solid fa-circle-xmark me-2"></i>
                  Er kon geen (prive) notitie met order informatie geplaatst
                  worden.
                </span>
              </ng-template>
            </p>
            <p>
              <strong>Manco reden:</strong>
              {{ getMancoTypeLabel(response.request!.mancoType) }}
            </p>
            <p>
              <strong>Verzendmethode:</strong>
              {{ response.request!.ishShippingMethodID }}
            </p>
          </div>
          <div class="col-md-6">
            <h5 class="border-bottom pb-2">Bezorgadres</h5>
            <p>
              <strong>Bedrijf:</strong>
              {{ response.request!.deliveryAddress.company }}
            </p>
            <p>
              <strong>Naam:</strong>
              {{ response.request!.deliveryAddress.firstName }}
              {{ response.request!.deliveryAddress.lastName }}
            </p>
            <p>
              <strong>Straat:</strong>
              {{ response.request!.deliveryAddress.address }}
            </p>
            <p>
              <strong>Postcode:</strong>
              {{ response.request!.deliveryAddress.postalCode }}
            </p>
            <p>
              <strong>Plaats:</strong>
              {{ response.request!.deliveryAddress.city }}
            </p>
            <p>
              <strong>Telefoonnummer:</strong>
              {{ response.request!.deliveryAddress.telephoneNumber }}
            </p>
            <p>
              <strong>E-mailadres:</strong>
              {{ response.request!.deliveryAddress.email }}
            </p>
          </div>
        </div>

        <!-- Artikelen Section -->
        <h5 class="border-bottom pb-2">Artikelen</h5>
          <table class="table mb-0">
            <thead>
              <tr>
                <th scope="col">Aantal</th>
                <th scope="col">SKU</th>
                <th scope="col">Beschrijving</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let orderline of response.request.orderlines">
                <td>{{ orderline.quantitySource }}</td>
                <td>{{ orderline.sku }}</td>
                <td>{{ orderline.nameSource }}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </div>
</div>
