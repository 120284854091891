import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function mancoTypeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === '') {
      return { invalidMancoType: true };
    }
    return null;
  };
}

export function stringLengthValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    if (value.length < min || value.length > max) {
      return { invalidStringLength: true, min, max };
    }
    return null;
  };
}

export function quantityValidator(orderline: {
  quantityDelivered: number;
  quantityReturned: number;
}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const quantity = control.value;
    const min = 0.01;
    const max = orderline.quantityDelivered - orderline.quantityReturned;

    if (quantity < min || quantity > max) {
      return { invalidQuantity: { min, max } };
    }
    return null;
  };
}

export function quantityRangeValidator(
  deliveredQuantity: number,
  returnedQuantity: number
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const quantity = control.value;

    if (
      !quantity ||
      isNaN(quantity) ||
      quantity < 0.01 ||
      quantity > deliveredQuantity - returnedQuantity
    ) {
      return { invalidQuantity: true };
    }
    return null;
  };
}

export function skuValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    const allowedSkuLength = 30; // online articles have max 20, but there are a couple of offline articles with lengths of 30 characters so whatevs
    const specialSkus = ['Koerier', 'XKosten'];

    if (!value) {
    }

    if (specialSkus.includes(value)) {
      return null;
    }

    if (value.length < 1 || value.length > allowedSkuLength) {
      return { invalidLength: 'SKU must be between 1 and 30 characters.' };
    }

    if (!/^[A-Z0-9]*$/.test(value)) {
      return {
        invalidFormat:
          'SKU must be uppercase and contain only letters and numbers.',
      };
    }

    return null;
  };
}
