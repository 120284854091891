import {
  Component,
  Inject,
  Input,
  QueryList,
  ViewChildren,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { skuValidator, quantityRangeValidator } from '../manco-request-validators';
import { Orderline } from '../../order-details';
import { MancoOrderline } from '../../manco-order-request';

@Component({
  selector: 'app-new-manco-form-orderlines',
  templateUrl: './new-manco-form-orderlines.component.html',
  styleUrls: ['./new-manco-form-orderlines.component.scss'],
})
export class NewMancoFormOrderlinesComponent {
  @Input() orderlines!: FormArray;
  @Input() originalLength!: number;

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private fb: FormBuilder
  ) {}

  createOriginalOrderlineGroup(orderline: Orderline, index: number): FormGroup {
    const group = this.fb.group({
      lineIDSource: [String(index + 1)],
      checked: [false],
      sku: [orderline.item.sku],
      name: [orderline.item.description],
      quantity: [
        { value: null, disabled: true },
        [
          Validators.required,
          quantityRangeValidator(orderline.quantityOrdered, orderline.quantityReturned),
        ],
      ],
      quantityOrdered: [orderline.quantityOrdered],
      units: [orderline.item.units],
      deliveryStatus: [orderline.deliveryStatus],
      source: ['original-order'],
    });

    this.toggleQuantityAndSetFocus(group, index);

    return group;
  }

  createManualOrderlineGroup(): FormGroup {
    const group = this.fb.group({
      lineIDSource: String(this.orderlines.length + 1),
      checked: [true],
      sku: ['', [Validators.required, skuValidator()]],
      name: [''],
      quantity: [
        null,
        [
          Validators.required,
          Validators.pattern(/^(0\.\d{1,2}|[1-9]\d{0,3}(\.\d{1,2})?|10000)$/),
        ],
      ],
      source: ['manually-added'],
    });

    this.toggleQuantityAndSetFocus(group, Number(group.get('lineIDSource')!.value) - 1);

    return group;
  }

  toggleQuantityAndSetFocus(group: FormGroup<any>, index: number): void {
    group.get('checked')!.valueChanges.subscribe((checked: FormGroup) => {
      const quantityControl = group.get('quantity')!;
      if (!checked) {
        quantityControl.disable();
      } else {
        quantityControl.enable();
        this.setFocus(index);
      }
    });
  }

  addNewOrderline(): void {
    const newOrderlineGroup = this.createManualOrderlineGroup();
    newOrderlineGroup.markAllAsTouched();
    this.setFocus(this.orderlines.length);
    this.orderlines.push(newOrderlineGroup);
  }

  setFocus(index: number): void {
    setTimeout(() => {
      const input = document.getElementById(`quantity-input-${index}`);
      if (input) {
        input.focus();
      }
    });
  }

  reset(): void {
    while (this.orderlines.length) {
      this.orderlines.removeAt(0);
    }
  }

  selectAllOrderlines(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.orderlines.controls.forEach((control) => {
      control.get('checked')?.setValue(isChecked);
    });
  }

  get hasSelectedOrderlines(): boolean {
    return this.orderlines.value.some((o: any) => {
      return o.checked;
    });
  }

  get selectedOrderlinesAreValid(): boolean {
    const hasInvalidOrderline = this.orderlines.controls.some((o: any) => {
      return !!o.get('checked').value && !!!o.valid;
    });

    return !hasInvalidOrderline;
  }

  get valid(): boolean {
    return this.hasSelectedOrderlines && this.selectedOrderlinesAreValid;
  }

  getOrderlines(): MancoOrderline[] {
    return this.orderlines.getRawValue()
      .map((orderline: any, originalIndex: number) => ({
        ...orderline,
        originalIndex,
      }))
      .filter(orderline => orderline.checked)
      .map((orderline: any, newIndex: number) => ({
        lineIDSource: String(orderline.originalIndex + 1),
        rowNumber: newIndex + 1,
        sku: orderline.sku,
        nameSource: orderline.name ? orderline.name : 'Handmatig toegevoegde regel',
        quantitySource: orderline.quantity,
        properties: { source: orderline.source },
      }));
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }

  get allOrderlinesSelected(): boolean {
    return this.orderlines.controls.length > 0 && this.orderlines.controls.every(
      control => control.get('checked')?.value
    );
  }
}
