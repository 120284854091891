<div class="d-flex">
  <div class="w-75">
    <h2>Verkooporder informatie</h2>
    <div class="mb-2 w-100">
      <strong>Freshdesk ticketnummers:</strong>
      <span *ngFor="let freshdeskTicketNumber of freshdeskTicketNumbers" class="ms-2">
        <a href="{{ freshdeskTicketNumber.toString() | freshdeskTicketLink }}" target="_blank">
          <button type="button" class="btn btn-sm btn-outline-primary">
            <i class="fa-regular fa-ticket fa-l"></i>
            {{ freshdeskTicketNumber }}
          </button>
        </a>
      </span>
    </div>
    <div class="mb-2 w-100">
      <label for="reason" class="form-label">
        <strong>Reden voor aanpassing</strong>
      </label>
      <select
        id="reason"
        class="form-select form-select-sm"
        [formControl]="toFormControl(info.get('reason'))"
        [formValidity]="info.get('reason')!"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      >
        <option [value]="''" disabled hidden>Selecteer een reden</option>
        <option [value]="'Order was goed'">Order was goed</option>
        <option [value]="'SKUs waren verkeerd'">SKUs waren verkeerd</option>
        <option [value]="'Aantallen waren verkeerd'">Aantallen waren verkeerd</option>
        <option [value]="'Step quantities klopten niet'">Step quantities klopten niet</option>
        <option [value]="'Producten uit assortiment'">Producten uit assortiment</option>
        <option [value]="'De verkooporder bevatte een INDI-special'">De verkooporder bevatte een INDI-special</option>
        <option [value]="'Overig'">Overig</option>
      </select>
      <div
        *ngIf="
          info.get('reason')?.hasError('required')
        "
        class="text-danger"
      >
        Reden is verplicht.
      </div>
      <div
        *ngIf="
          info.get('reason')?.hasError('invalidReason') &&
          info.get('reason')?.touched
        "
        class="text-danger"
      >
        Kies een geldige reden.
      </div>
    </div>
    <div class="mb-2 w-100">
      <label class="form-label" for="orderReference">
        <strong>Orderreferentie</strong>
      </label>
      <input
        type="text"
        id="orderReference"
        class="form-control form-control-sm"
        maxlength="36"
        [formControl]="toFormControl(info.get('orderReference'))"
      />
    </div>
    <div class="mb-2 w-100">
      <label class="form-label" for="note">
        <strong>Notitie</strong>
      </label>
      <textarea
        type="text"
        id="note"
        class="form-control form-control-sm"
        [formControl]="toFormControl(info.get('note'))"
        rows="3"
        maxlength="1024"
      ></textarea>
    </div>
    <div class="mb-2 w-100">
      <label class="form-label" for="fixedDeliveryDate">
        <strong>Andere leverdatum</strong>
      </label>
      <input
        type="date"
        id="fixedDeliveryDate"
        class="form-control"
        [formControl]="toFormControl(info.get('fixedDeliveryDate'))"
        [min]="datePickerMin"
        [max]="datePickerMax"
      />
    </div>
  </div>
</div>
