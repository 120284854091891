import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { NewSalesorderOrderlinesComponent } from './new-salesorder-orderlines/new-salesorder-orderlines.component';
import { NewSalesorderShippingDetailsComponent } from './new-salesorder-shipping-details/new-salesorder-shipping-details.component';
import { NewSalesorderInvoiceDetailsComponent } from './new-salesorder-invoice-details/new-salesorder-invoice-details.component';
import { NewSalesorderInfoComponent } from './new-salesorder-info/new-salesorder-info.component';
import { ActivatedRoute } from '@angular/router';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { Profile } from 'src/app/general-components/profile/profile';
import { AuthService } from 'src/app/auth/auth.service';
import { SHIPPING_METHOD_CODES } from './shipping-methods.const';
import { HttpErrorResponse } from '@angular/common/http';
import { Address, IndiCustomer } from '../indi-customer';
import { CombinedData, NewSalesorderService } from './new-salesorder.service';
import { ApiResponse } from '../api-response';
import { APIVerkooporder } from '../salesorder';
import { APIVerkoopordersToWrite } from '../salesorderToWrite';

@Component({
  selector: 'app-new-salesorder',
  templateUrl: './new-salesorder.component.html',
  styleUrls: ['./new-salesorder.component.scss'],
})
export class NewSalesorderComponent implements OnInit, AfterViewInit {
  profile: Profile | undefined;
  APIVerkooporder: APIVerkooporder = { ticketId: 0, orderDetails: {} } as APIVerkooporder;
  indiCustomer: IndiCustomer = {} as IndiCustomer;
  apiResponse?: ApiResponse;
  form!: FormGroup;
  ids: number[] = [];
  ticketIds: number[] = [];
  isLoading: boolean = true;
  isSent: boolean = false;
  orderError?: HttpErrorResponse;
  shippingAddresses!: Address[];
  nightShippingAddress!: Address;
  defaultInvoiceAddress!: Address;

  @ViewChild(NewSalesorderOrderlinesComponent)
  orderlinesComponent?: NewSalesorderOrderlinesComponent;
  @ViewChild(NewSalesorderShippingDetailsComponent)
  shippingComponent?: NewSalesorderShippingDetailsComponent;
  @ViewChild(NewSalesorderInvoiceDetailsComponent)
  invoiceComponent?: NewSalesorderInvoiceDetailsComponent;
  @ViewChild(NewSalesorderInfoComponent)
  infoComponent?: NewSalesorderInfoComponent;

  feedbackSettings: FeedbackSettings = {
      identifier: 'generalFeedback',
      show: false,
      type: FeedbackType.error,
      message: '',
      buttonText: '',
      closeButton: false,
    };

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private route: ActivatedRoute,
    private service: NewSalesorderService,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.profile = this.authService.getUserInfo();
    this.ids = this.route.snapshot.queryParamMap.getAll('id')
      .map(id => Number(id))
      .filter(id => !isNaN(id) && id !== 0);
    this.form = this.createFormGroup();
  }

  ngAfterViewInit(): void {
    if (this.ids.length === 0) {
      this.feedbackSettings.message = 'Geen geldige id gegeven';
      this.feedbackSettings.show = true;
      this.isLoading = false;
      this.orderError = undefined;
    } else {
      this.service.getCombinedData(this.ids).subscribe(
        (combinedData: CombinedData) => {
          this.ticketIds = combinedData.APIVerkooporders.ticketIds;
          this.APIVerkooporder = combinedData.APIVerkooporders.verkooporder;
          this.indiCustomer = combinedData.indiCustomer;
          this.initializeAddresses();
          this.initializeForm();
          this.isLoading = false;
        },
        (error: any) => {
          console.error('Failed to get salesorder information', error);
          this.feedbackSettings.message = 'Er is iets fout gegaan bij het ophalen van de verkooporder' +
          (error.error?.exception ?
            ': \n' + error.error?.exception :
            '');
          this.feedbackSettings.show = true;
          this.isLoading = false;
        }
      );
    }
  }

  initializeAddresses(): void {
    this.defaultInvoiceAddress = this.indiCustomer.addresses
      .filter((address => address.type === 'atDefaultForInvoice'
        || address.type === 'atDefaultForInvoiceAndShipping'))[0];

    this.nightShippingAddress = this.indiCustomer.addresses.filter(address => address.nightShipping)[0];

    this.shippingAddresses = this.indiCustomer.addresses
      .filter((address => address.type !== 'atDefaultForInvoice'
        && address.type !== 'atForInvoice'));
  }

  createFormGroup(): FormGroup {
    return this.fb.group({
      orderlines: this.fb.array([]),
      shippingDetails: this.fb.group({}),
      invoiceDetails: this.fb.group({}),
      info: this.fb.group({}),
    });
  }

  initializeForm(): void {
    this.setOrderlines();
    this.setShippingDetails();
    this.shippingComponent?.initializeAddressData();
    this.setInvoiceDetails();
    this.setInfo();
  }

  setOrderlines(): void {
    this.form.setControl(
      'orderlines',
      this.orderlinesComponent!.createOrderlines(
        this.APIVerkooporder.orderDetails.orderlines
      )
    );
  }

  setShippingDetails(): void {
    this.form.setControl(
      'shippingDetails',
      this.shippingComponent!.createShippingDetails(
        this.APIVerkooporder.orderDetails.deliveryAddress
      )
    );
  }

  setInvoiceDetails(): void {
    this.form.setControl(
      'invoiceDetails',
      this.invoiceComponent!.createInvoiceDetails(
        this.APIVerkooporder.orderDetails.customer.invoiceEmailAddress,
        this.defaultInvoiceAddress
      )
    );
  }

  setInfo(): void {
    this.form.setControl(
      'info',
      this.infoComponent!.createInfo(this.APIVerkooporder.orderDetails.customerRef || '')
    );
  }

  get orderlines(): FormArray {
    return this.form.get('orderlines') as FormArray;
  }

  get shippingDetails(): FormGroup {
    return this.form.get('shippingDetails') as FormGroup;
  }

  get invoiceDetails(): FormGroup {
    return this.form.get('invoiceDetails') as FormGroup;
  }

  get info(): FormGroup {
    return this.form.get('info') as FormGroup;
  }

  onSubmit(): void {
    const salesorders: APIVerkoopordersToWrite = this.makeAPIVerkoopordersToWrite();

    this.isLoading = true;
    this.orderError = undefined;
    this.service.postAPIVerkoopordersToWrite(salesorders).subscribe(
      (apiResponse: ApiResponse) => {
        this.apiResponse = apiResponse;
        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error("Failed to submit the salesorder to the backend", error);
        this.isLoading = false;
        this.orderError = error;
      }
    )
  }

  makeAPIVerkoopordersToWrite(): APIVerkoopordersToWrite {
    return {
      ids: this.ids,
      verkooporder: {
        orderDetails: {
          customer: {
            invoiceEmailAddress: this.invoiceComponent!.getEmailAddress(),
          },
          customerRef: this.infoComponent!.getOrderReference(),
          shippingMethod: SHIPPING_METHOD_CODES[this.shippingComponent!.getShippingMethod()],
          fixedDeliveryDate: this.infoComponent!.getFixedDeliveryDate(),
          deliveryAddress: this.shippingComponent!.getDeliveryAddress(),
          orderlines: this.orderlinesComponent!.getOrderlines(),
        },
        reason: this.infoComponent!.getReason(),
        assessmentNotes: this.infoComponent!.getAssessmentNotes(),
        createdByHint: this.profile?.firstName + ' ' + this.profile?.lastName,
      }
    }
  }

  hideOrderError(): void {
    this.orderError = undefined;
  }
}
