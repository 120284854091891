<form [formGroup]="form" (ngSubmit)="onSubmit()" class="needs-validation">
  <app-new-manco-form-orderlines
  [orderlines]="orderlines"
  [originalLength]="orderDetails.orderlines.length"
  >
  </app-new-manco-form-orderlines>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column w-50">
      <h3 class="text-center mb-3">Manco order gegevens</h3>
      <label class="form-label" for="mancoTypeSelect"
        ><strong>Reden opnieuw bestellen:</strong></label
      >
      <select
        class="form-select form-select-sm"
        id="mancoTypeSelect"
        formControlName="mancoType"
        [formValidity]="form.get('mancoType')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      >
        <option [value]="''" disabled hidden>Selecteer reden</option>
        <option *ngFor="let option of mancoTypeOptions" [value]="option.value">
          {{ option.label }}
        </option>
      </select>

      <div
        *ngIf="form.get('mancoType')?.invalid"
        class="invalid-feedback"
      >
        Kies een geldige reden.
      </div>
      <div class="d-flex flex-column mt-4">
        <label class="form-label" for="customerRef"
          ><strong>Orderreferentie:</strong></label
        >
        <input
          id="customerRef"
          type="text"
          class="form-control form-control-sm"
          formControlName="customerRef"
        />
        <div
          *ngIf="form.get('forOrderNumber')?.errors?.['maxlength']"
          class="text-danger"
        >
          Order referentie mag maximaal 36 karakters zijn.
        </div>
      </div>
      <div class="d-flex flex-column mt-4">
        <strong>Notitie</strong>
        <textarea
          class="form-control"
          placeholder="Notitie aan/voor de Check-upper voor de follow-up"
          formControlName="note"
          rows="8"
          maxlength="255"
        ></textarea>
      </div>
    </div>
    <app-new-manco-form-deliveryaddress
      [shippingMethod]="orderDetails.shippingMethod"
      [form]="form"
      class="w-50"
    ></app-new-manco-form-deliveryaddress>
  </div>
  <div class="d-flex h-100 mt-3">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="!orderlinesComponent?.valid ||
                  !deliveryAddressComponent?.valid ||
                  !form.get('mancoType')?.valid"
    >
      <i class="fa-regular fa-shopping-cart fa-l"></i> Manco aanmaken
    </button>
  </div>
</form>