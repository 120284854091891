import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { INDI_CUSTOMERS_URL, SALESORDERS_SERVICE_URL } from 'src/app/injection-tokens';
import { Observable } from 'rxjs';
import { IndiCustomer } from '../indi-customer';
import { shareReplay, switchMap } from 'rxjs/operators';
import { ApiResponse } from '../api-response';
import { APIVerkooporders } from '../salesorder';
import { APIVerkoopordersToWrite } from '../salesorderToWrite';

export interface CombinedData {
  APIVerkooporders: APIVerkooporders;
  indiCustomer: IndiCustomer;
}

@Injectable({
  providedIn: 'root',
})
export class NewSalesorderService {
  constructor(
    @Inject(SALESORDERS_SERVICE_URL) readonly salesordersServiceURL: string,
    @Inject(INDI_CUSTOMERS_URL) readonly indiCustomersUrl: string,
    readonly http: HttpClient
  ) {}

  getCombinedData(ids: number | number[]): Observable<CombinedData> {
    const idArray = Array.isArray(ids) ? ids : [ids];
    return this.getAPIVerkooporders(idArray).pipe(
      switchMap((APIVerkooporders: APIVerkooporders) =>
        this.getIndiCustomers(APIVerkooporders.verkooporder.customerNumber).pipe(
          switchMap(indiCustomers => {
            const combined: CombinedData = {
              APIVerkooporders: APIVerkooporders,
              indiCustomer: indiCustomers[0]
            };
            return [combined];
          })
        )
      ),
      shareReplay(1)
    );
  }

  getIndiCustomers(customerNumber: string): Observable<IndiCustomer[]> {
    return this.http.get<IndiCustomer[]>(
      this.indiCustomersUrl + '?customerNumber=' + customerNumber + '&fetchSegments=true'
    );
  }

  getAPIVerkooporders(ids: number[]): Observable<APIVerkooporders> {
    let url = this.salesordersServiceURL + '?id=';
    url += ids.join('&id=');
    return this.http.get<APIVerkooporders>(url);
  }

  postAPIVerkoopordersToWrite(salesorders: APIVerkoopordersToWrite): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.salesordersServiceURL,
      salesorders
    );
  }
}
