<div class="d-flex justify-content-center my-4">
  <div class="card w-50 position-relative">
    <div class="card-body">
      <h3 class="card-title mb-3">
        <i class="fa-solid fa-boxes-packing me-3 text-success"></i>
        Het is gelukt! Dit is de verkooporder:

        <a
          class="me-3"
          href="{{ response.orderNumber! | freshdeskOmsOrderLink }}"
          target="_blank"
          >
          <strong>{{ response.orderNumber }}</strong> 
        </a> 
      </h3>
      <span
        *ngIf="response.freshdeskNoteCreated; else noComment"
        class="ms-3 text-success"
      >
        <i class="fa-solid fa-circle-check me-2"></i>
        Er is een (privé) notitie met order informatie toegevoegd.
      </span>
      <ng-template #noComment>
        <span class="ms-3 text-danger">
          <i class="fa-solid fa-circle-xmark me-2"></i>
          Er kon geen (privé) notitie met order informatie geplaatst
          worden.
        </span>
      </ng-template>
    </div>
  </div>
</div>
