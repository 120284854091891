import { Component, Input, SimpleChanges } from '@angular/core';
import { AtpService } from 'src/app/general-services/atp/atp.service';
import {
  AtpRequestItem,
  AtpResponseItem,
} from 'src/app/general-services/atp/atp-interfaces';

@Component({
  selector: 'app-atp-info',
  templateUrl: './atp-info.component.html',
  styleUrl: './atp-info.component.scss',
})
export class AtpInfoComponent {
  @Input() quantity?: number;
  @Input() sku?: string;
  @Input() units?: string;

  isLoading: boolean = false;
  errorOccurred: boolean = false;
  atpResponseItem: AtpResponseItem | null = null;
  iconColor: string = 'primary-color';

  constructor(private service: AtpService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sku'] && !changes['sku'].firstChange) {
      this.reset();
    }
  }

  reset(): void {
    this.atpResponseItem = null;
    this.errorOccurred = false;
  }
  setIconColor(color: string): void {
    this.iconColor = color;
  }

  getAtpInfo() {
    this.isLoading = true;

    const quantity = this.quantity ?? 1;

    const requestItem: AtpRequestItem = {
      sku: this.sku!,
      quantity: quantity.toString(),
    };

    this.service.getSingleAtpInfo(requestItem).subscribe(
      (atpResponseItem: AtpResponseItem) => {
        this.atpResponseItem = atpResponseItem;
        this.errorOccurred = false;
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.errorOccurred = true;
        this.isLoading = false;
      }
    );
  }
}
