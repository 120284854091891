<div *ngIf="shippingDetails">
  <div class="w-75">
    <h2>Verzendadres</h2>
    <div class="mb-2">
      <label class="form-label" for="shippingMethod">
        <strong>Verzendmethode</strong>
      </label>
      <select
        id="shippingMethod"
        class="form-select form-select-sm"
        [formControl]="toFormControl(shippingDetails.get('shippingMethod'))"
      >
        <option *ngIf="hasNightShipping" [value]="'Nachtbezorging'">Nachtbezorging</option>
        <option *ngIf="hasPickUpAtCounter" [value]="'Afhalen bij de balie'">Afhalen bij de balie</option>
        <option [value]="'Dagbezorging'">Dagbezorging</option>
        <option [value]="'Ander adres kiezen'">Ander adres kiezen</option>
      </select>
    </div>

    <!-- Shipping Address (Nightshipping) -->
    <div
      *ngIf="shippingDetails.get('shippingMethod')?.value === 'Nachtbezorging'"
    >
      <strong>Nachtbezorgingadres</strong>
      <br />
      <div>
        {{ nightShippingAddress?.companyName }}
        <br />
        {{ nightShippingAddress?.firstName }} {{ nightShippingAddress?.lastName }}
        <br />
        {{ nightShippingAddress?.address }}
        <br />
        {{ nightShippingAddress?.postalCode }}
        {{ nightShippingAddress?.city}}
        <br />
        <span *ngIf="nightShippingAddress?.countryCode === 'NL'">
          Nederland (NL)
        </span>
        <span *ngIf="nightShippingAddress?.countryCode === 'DE'">
          Duitsland (DE)
        </span>
      </div>
    </div>

    <!-- Shipping Address (Dayshipping) -->
    <div *ngIf="shippingDetails.get('shippingMethod')?.value === 'Dagbezorging'">
      <label class="form-label" for="addressSelect">
        <strong>Selecteer een verzendadres</strong>
      </label>
      <select
        id="addressSelect"
        class="form-select form-select-sm"
        >
        <option [value]="''" disabled hidden>Selecteer een verzendadres</option>
        <ng-container *ngFor="let address of addresses; index as i">
          <option [value]="address" (click)="setDeliveryAddressFormValues(i)">
            <span *ngIf="i === 0">(Voorkeursadres) </span>{{ address.address }}, {{ address.postalCode }} {{ address.city }} [{{ address.companyName }}]
          </option>
        </ng-container>
      </select>
      <div class="mt-2">
        {{ this.shippingDetails.get('deliveryAddress')?.value.companyName }}
        <br />
        {{ this.shippingDetails.get('deliveryAddress')?.value?.firstName }} {{ this.shippingDetails.get('deliveryAddress')?.value?.lastName }}
        <br />
        {{ this.shippingDetails.get('deliveryAddress')?.value?.address }}
        <br />
        {{ this.shippingDetails.get('deliveryAddress')?.value?.postalCode }}
        {{ this.shippingDetails.get('deliveryAddress')?.value?.city}}
        <br />
        <span *ngIf="this.shippingDetails.get('deliveryAddress')?.value?.countryCode === 'NL'">
          Nederland (NL)
        </span>
        <span *ngIf="this.shippingDetails.get('deliveryAddress')?.value?.countryCode === 'DE'">
          Duitsland (DE)
        </span>
      </div>
    </div>

    <!-- Shipping Address (Ander adres kiezen) -->
    <div
      *ngIf="shippingDetails.get('shippingMethod')?.value === 'Ander adres kiezen'"
    >
      <h2>Verzendadres</h2>
      <div class="justify-content-center align-items-start w-75">
        <div class="mb-2 w-100">
          <label class="form-label" for="companyName">
            <strong>Bedrijfsnaam</strong>
          </label>
          <input
            type="text"
            id="companyName"
            class="form-control form-control-sm"
            [formControl]="toFormControl(shippingDetails.get('deliveryAddress.companyName'))"
            [formValidity]="shippingDetails.get('deliveryAddress.companyName')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
          <div
            *ngIf="shippingDetails.get('deliveryAddress.companyName')?.invalid"
            class="text-danger"
          >
            Bedrijfsnaam is verplicht.
          </div>
        </div>
        <div class="d-flex flex-wrap mb-2 w-100 gap-2">
          <div class="flex-grow-1">
            <label for="firstName" class="form-label">
              <strong>Voornaam</strong>
            </label>
            <input
              type="text"
              id="firstName"
              class="form-control form-control-sm"
              [formControl]="toFormControl(shippingDetails.get('deliveryAddress.firstName'))"
              [formValidity]="shippingDetails.get('deliveryAddress.firstName')!"
              [formValidityType]="'invalid'"
              [formValidityNeedsTouch]=false
            />
            <div
              *ngIf="shippingDetails.get('deliveryAddress.firstName')?.invalid"
              class="text-danger"
            >
              Voornaam is verplicht.
            </div>
          </div>
          <div class="flex-grow-1">
            <label for="lastName" class="form-label">
              <strong>Achternaam</strong>
            </label>
            <input
              type="text"
              id="lastName"
              class="form-control form-control-sm"
              [formControl]="toFormControl(shippingDetails.get('deliveryAddress.lastName'))"
              [formValidity]="shippingDetails.get('deliveryAddress.lastName')!"
              [formValidityType]="'invalid'"
              [formValidityNeedsTouch]=false
            />
            <div
              *ngIf="shippingDetails.get('deliveryAddress.lastName')?.invalid"
              class="text-danger"
            >
              Achternaam is verplicht.
            </div>
          </div>
        </div>
        <div class="mb-2 w-100">
          <label class="form-label" for="address">
            <strong>Adres</strong>
          </label>
          <input
            type="text"
            id="address"
            class="form-control form-control-sm"
            [formControl]="toFormControl(shippingDetails.get('deliveryAddress.address'))"
            [formValidity]="shippingDetails.get('deliveryAddress.address')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
          <div
            *ngIf="shippingDetails.get('deliveryAddress.address')?.invalid"
            class="text-danger"
          >
            Adres is verplicht.
          </div>
        </div>
        <div class="mb-2 w-100">
          <label class="form-label" for="postalCode">
            <strong>Postcode</strong>
          </label>
          <input
            type="text"
            id="postalCode"
            class="form-control form-control-sm"
            [formControl]="toFormControl(shippingDetails.get('deliveryAddress.postalCode'))"
            [formValidity]="shippingDetails.get('deliveryAddress.postalCode')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
          <div
            *ngIf="shippingDetails.get('deliveryAddress.postalCode')?.invalid"
            class="text-danger"
          >
            Postcode is verplicht.
          </div>
        </div>
        <div class="mb-2 w-100">
          <label class="form-label" for="city">
            <strong>Plaats</strong>
          </label>
          <input
            type="text"
            id="city"
            class="form-control form-control-sm"
            [formControl]="toFormControl(shippingDetails.get('deliveryAddress.city'))"
            [formValidity]="shippingDetails.get('deliveryAddress.city')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          />
          <div
            *ngIf="
              shippingDetails.get('deliveryAddress.city')?.invalid
            "
            class="text-danger"
          >
            Plaats is verplicht.
          </div>
        </div>
        <div class="mb-2 w-100">
          <label for="countryCode" class="form-label">
            <strong>Land</strong>
          </label>
          <select
            id="countryCode"
            class="form-select form-select-sm"
            [formControl]="
              toFormControl(shippingDetails.get('deliveryAddress.countryCode'))
            "
            [formValidity]="shippingDetails.get('deliveryAddress.countryCode')!"
            [formValidityType]="'invalid'"
            [formValidityNeedsTouch]=false
          >
            <option [value]="''" disabled hidden>Selecteer een land</option>
            <option [value]="'NL'">Nederland (NL)</option>
            <option [value]="'DE'">Duitsland (DE)</option>
          </select>
          <div
            *ngIf="
              shippingDetails.get('deliveryAddress.countryCode')?.hasError('required')
            "
            class="text-danger"
          >
            Land is verplicht.
          </div>
          <div
            *ngIf="
              shippingDetails.get('deliveryAddress.countryCode')?.hasError('invalidCountry')
            "
            class="text-danger"
          >
            Land moet Nederland (NL) of Duitsland (DE) zijn.
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2 w-100">
      <label class="form-label" for="emailAddress">
        <strong>E-mailadres</strong>
      </label>
      <input
        type="text"
        id="emailAddress"
        class="form-control form-control-sm"
        [formControl]="toFormControl(shippingDetails.get('deliveryAddress.email'))"
        [formValidity]="shippingDetails.get('deliveryAddress.email')!"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="
          shippingDetails.get('deliveryAddress.email')?.invalid
        "
        class="text-danger"
      >
        E-mailadres is verplicht.
      </div>
    </div>
  </div>
</div>
