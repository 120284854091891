import { Component, OnInit } from '@angular/core';
import { SalesorderOverviewService } from './salesorder-overview.service';
import { DateFilters, ErrorFilters } from './salesorder-filter/salesorder-filter.pipe';
import { APIVerkooporderWithCompany } from '../salesorder';
import { HttpErrorResponse } from '@angular/common/http';
import { FeedbackSettings } from 'src/app/general-components/feedback/feedback-settings';
import { FeedbackType } from 'src/app/general-components/feedback/feedback-type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-salesorder-overview',
  templateUrl: './salesorder-overview.component.html',
  styleUrls: ['./salesorder-overview.component.scss'],
})
export class SalesorderOverviewComponent implements OnInit {
  salesorders: APIVerkooporderWithCompany[] = [];
  selectedSalesorders: number[] = [];
  page: number = 1;
  searchText: string = '';
  selectedDateFilter: DateFilters = DateFilters.ThreeDays;
  selectedErrorFilter: ErrorFilters = ErrorFilters.Both;
  hideProcessedSalesorders: boolean = false;
  
  isLoading: boolean = true;

  feedbackSettings: FeedbackSettings = {
    identifier: 'generalFeedback',
    show: false,
    type: FeedbackType.error,
    message: 'Er is iets fout gegaan met het ophalen van de verkooporders.',
    buttonText: '',
    closeButton: false,
  };

  constructor(
    private service: SalesorderOverviewService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getSalesordersFromApi();
  }

  getSalesordersFromApi() {
    this.service.getSalesordersWithCompany().subscribe(
      (salesorders: APIVerkooporderWithCompany[]) => {
        this.salesorders = salesorders;
        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.feedbackSettings.show = true;
        this.isLoading = false;
      }
    );
  }

  onSearchTextChange(): void {
    this.page = 1;
  }

  onSelectedDateFilterChange(filter: DateFilters): void {
    this.selectedDateFilter = filter;
  }

  onSelectedErrorFilterChange(filter: ErrorFilters): void {
    this.selectedErrorFilter = filter;
  }

  onHideProcessedSalesordersChange(value: boolean): void {
    this.hideProcessedSalesorders = value;
  }

  onSubmit(): void {
    this.router.navigate(['verkooporders/nieuwe-verkooporder'], {
      queryParams: {
        id: this.selectedSalesorders.sort(),
      },
    });
  }
}
