<h2>Factuuradres</h2>
{{ invoiceAddress.companyName }}
<br />
{{ invoiceAddress.firstName }}
{{ invoiceAddress.lastName }}
<br />
{{ invoiceAddress.address }}
<br />
{{ invoiceAddress.postalCode }}
{{ invoiceAddress.city }}
<br />
<span *ngIf="invoiceAddress.countryCode == 'NL'">
  Nederland (NL)
</span>
<span *ngIf="invoiceAddress.countryCode == 'DE'">
  Duitsland (DE)
</span>
<br />

<div class="justify-content-center align-items-start w-75">
  <div class="mt-2 w-100">
    <label class="form-label" for="emailAddress">
      <strong>E-mailadres</strong>
    </label>
    <input
      type="email"
      id="emailAddress"
      class="form-control form-control-sm"
      [formControl]="toFormControl(invoiceDetails.get('emailAddress'))"
      [formValidity]="invoiceDetails.get('emailAddress')!"
      [formValidityType]="'invalid'"
      [formValidityNeedsTouch]=false
    />
    <div
      *ngIf="
        invoiceDetails.get('emailAddress')?.invalid &&
        invoiceDetails.get('emailAddress')?.touched
      "
      class="text-danger"
    >
      <div
        *ngIf="
              invoiceDetails.get('emailAddress')?.errors?.['required']
            "
      >
        E-mailadres is verplicht.
      </div>
      <div
        *ngIf="
              invoiceDetails.get('emailAddress')?.errors?.['email']
            "
      >
        Voer een geldig e-mailadres in.
      </div>
    </div>
  </div>
</div>
