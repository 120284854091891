import { Component, Input } from '@angular/core';
import { ApiResponse } from '../../api-response';

@Component({
  selector: 'app-new-salesorder-sent',
  templateUrl: './new-salesorder-sent.component.html',
  styleUrls: ['./new-salesorder-sent.component.scss']
})
export class NewSalesorderSentComponent  {
  @Input() response!: ApiResponse;
}
