<div
  class="d-flex justify-content-center"
  *ngIf="settings.show"
  [@fade]="fadeOutStart"
  (@fade.done)="animationDone($event)"
>
  <div class="open-close-container"></div>
  <div class="p-2">
    <div class="d-flex justify-content-end mb-4" *ngIf="settings.closeButton">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="hide()"
      ></button>
    </div>
    <div class="mx-4 mb-4" [ngClass]="{ 'mt-4': !settings.closeButton }">
      <i
        *ngIf="settings.type === feedbackType().error"
        class="fa-solid fa-circle-exclamation fa-2xl primary-color"
        style="vertical-align: -0.145rem"
      ></i>
      <i
        *ngIf="settings.type === feedbackType().noResults"
        class="fa-regular fa-magnifying-glass fa-2xl"
        style="vertical-align: -0.145rem"
      ></i>
      <i
        *ngIf="settings.type === feedbackType().succes"
        class="fa-regular fa-circle-check fa-2xl green"
        style="vertical-align: -0.145rem"
      ></i>
      <img
        [src]="'assets/cat.png'"
        height="100"
        *ngIf="settings.type === feedbackType().cat"
      />
      <span class="ms-4 fs-4" style="white-space: pre-line;"> {{ settings.message }} </span>
      <div
        class="d-flex justify-content-center mt-4"
        *ngIf="settings.buttonText"
      >
        <button
          type="button"
          class="btn btn-outline-primary"
          (click)="sendUpdate()"
        >
          {{ settings.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
