<table class="table">
  <thead>
    <tr>
      <th scope="col" class="width-3 text-center">Kies</th>
      <th scope="col" class="width-5">Aantal</th>
      <th scope="col" class="width-15">SKU</th>
      <th scope="col" class="width-20">Productnaam</th>
      <th scope="col" class="width-5">Besteld</th>
      <th scope="col" class="width-10">Orderregelstatus</th>
      <th scope="col" class="width-10">ATP</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="text-center" colspan="1">
        <input
          id="selectAll"
          type="checkbox"
          class="form-check-input"
          [checked]="allOrderlinesSelected"
          (change)="selectAllOrderlines($event)"
        />
      </td>
      <td colspan="6">
        <label for="selectAll">
          Selecteer alles
        </label>
      </td>
    </tr>
    <ng-container *ngFor="let orderline of orderlines.controls; let i = index">
      <tr class="align-middle" [ngClass]="{ 'offset-rows-color': i % 2 === 0 }">
        <td class="text-center">
          <input
            type="checkbox"
            class="form-check-input"
            [formControl]="toFormControl(orderline.get('checked'))"
          />
        </td>
        <td>
          <input
            type="number"
            class="form-control form-control-sm no-arrows-in-number-input"
            placeholder="Aantal"
            [formControl]="toFormControl(orderline.get('quantity'))"
            id="quantity-input-{{i}}"
          />
        </td>
        <td *ngIf="i <= originalLength -1">
          <a
            class="c-block-media__url"
            href="{{ baseIndiUrl }}nl-nl/p/{{ orderline.get('sku')?.value }}"
            target="_blank"
            >{{ orderline.get('sku')?.value }}
          </a>
        </td>
        <td *ngIf="i > originalLength -1">
          <input
            type="text"
            class="form-control form-control-sm"
            placeholder="SKU"
            minlength="1"
            maxlength="30"
            autocomplete="off"
            [formControl]="toFormControl(orderline.get('sku'))"
          />
        </td>
        <td *ngIf="i > originalLength -1">
          <a
            [href]="baseIndiUrl + 'nl-nl/p/' + (orderline.get('sku')?.value || '')"
            target="_blank"
          >
            <button type="button" class="btn btn-outline-primary">
              <i class="fa-regular fa-link"></i>
            </button>
          </a>
        </td>
        <td>
          <a
            class="c-block-media__url"
            href="{{ baseIndiUrl }}nl-nl/p/{{orderline.get('name')?.value }}"
            target="_blank"
            >{{ orderline.get('name')?.value }}
          </a>
        </td>
        <td>
          {{ orderline.get('quantityOrdered')?.value }} {{ orderline.get('units')?.value }}
        </td>
        <td *ngIf="i <= originalLength -1">
          <app-delivery-status-column
            [orderline]="orderline.value"
          ></app-delivery-status-column>
        </td>
        <td>
          <app-atp-info
            [quantity]="orderline.get('quantity')?.value"
            [sku]="orderline.get('sku')?.value"
          ></app-atp-info>
        </td>
      </tr>
      <tr [ngClass]="{ 'offset-rows-color': i % 2 === 0 }">
        <td colspan="7" class="p-0">
          <ul class="text-danger my-0 mx-3">
            <ng-container *ngIf="orderline.get('checked')?.value">
              <div *ngIf="orderline.get('quantity')?.invalid">
                <li
                  *ngIf="orderline.get('quantity')?.errors?.['required']"
                  class="m-1"
                >
                  Aantal is verplicht en moet een getal zijn.
                </li>
                <li
                  *ngIf="orderline.get('quantity')?.errors?.['invalidQuantity']"
                  class="m-1"
                >
                  Aantal moet tussen 1 en het maximale aantal geleverde stuks liggen.
                </li>
                <li
                  *ngIf="orderline.get('quantity')?.errors?.['pattern']"
                  class="m-1"
                >
                  Aantal moet een getal tussen 0.01 en 10000 zijn.
                </li>
              </div>
              <div *ngIf="orderline.get('sku')?.invalid">
                <li *ngIf="orderline.get('sku')?.errors?.['invalidLength']" class="m-1">
                  SKU is verplicht en moet tussen de 1 en 30 karakters liggen.
                </li>
                <li *ngIf="orderline.get('sku')?.errors?.['invalidFormat']" class="m-1">
                  SKU mag alleen uit nummers en hoofdletters bestaan.
                </li>
              </div>
            </ng-container>
          </ul>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="orderlines.length > 6">
      <td class="text-center" colspan="1">
        <input
          id="selectAll2"
          type="checkbox"
          class="form-check-input"
          [checked]="allOrderlinesSelected"
          (change)="selectAllOrderlines($event)"
        />
      </td>
      <td colspan="6">
        <label for="selectAll2">
          Selecteer alles
        </label>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="!hasSelectedOrderlines" class="text-danger">
  Selecteer ten minste één geldige orderregel.
</div>
<div *ngIf="hasSelectedOrderlines && !selectedOrderlinesAreValid" class="text-danger">
  Niet alle geselecteerde orderregels zijn geldig.
</div>

<div class="d-flex mt-3">
  <button type="button" class="btn btn-outline-primary" (click)="addNewOrderline()">
    <i class="fa-regular fa-plus fa-lg"></i> Orderregel toevoegen
  </button>
</div>
