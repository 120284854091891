<h1 class="text-center border-bottom pb-3">Verkooporder overzicht</h1>

<div [ngClass]="{ 'd-none': !isLoading }">
  <app-loading-gears></app-loading-gears>
</div>

<div *ngIf="feedbackSettings.show">
  <div class="feedback_box">
    <app-feedback [(settings)]="feedbackSettings"></app-feedback>
  </div>
</div>

<div [ngClass]="{ 'd-none': isLoading || feedbackSettings.show }">
  <div
    class="d-flex justify-content-between mb-2 pt-2"
  >
    <app-salesorder-filter
      (selectedDateFilterChange)="onSelectedDateFilterChange($event)"
      (selectedErrorFilterChange)="onSelectedErrorFilterChange($event)"
      (hideProcessedSalesordersChange)="onHideProcessedSalesordersChange($event)"
    ></app-salesorder-filter>
    <div class="searchbar">
      <input
        class="search_input"
        type="text"
        [(ngModel)]="searchText"
        (ngModelChange)="onSearchTextChange()"
        placeholder="Zoeken..."
      />
      <div class="search_icon">
        <i class="fas fa-search"></i>
      </div>
    </div>
  </div>
  <div>
    <app-salesorder-overview-table
      [salesorders]="salesorders"
      [searchText]="searchText"
      [selectedDateFilter]="selectedDateFilter"
      [selectedErrorFilter]="selectedErrorFilter"
      [hideProcessedSalesorders]="hideProcessedSalesorders"
      [page]="page"
      (selectionChanged)="selectedSalesorders = $event"
    ></app-salesorder-overview-table>
  </div>

  <div class="d-flex flex-row justify-content-between">
    <button
      type="submit"
      class="btn btn-primary"
      [disabled]="selectedSalesorders.length === 0"
      (click)="onSubmit()"
    >
      <i class="fa-regular fa-shopping-cart fa-lg"></i> Verkooporder aanmaken
    </button>

    <pagination-controls
    (pageChange)="page = $event"
    previousLabel="Vorige"
    nextLabel="Volgende"
    [autoHide]="true"
    ></pagination-controls>
  </div>
</div>