import { Pipe, PipeTransform } from '@angular/core';
import { APIVerkooporderWithCompany } from '../salesorder';

@Pipe({
  name: 'filterBySameCustomer'
})
export class SalesorderFilterSameCustomerPipe implements PipeTransform {
  transform(salesorders: APIVerkooporderWithCompany[], customerNumber: string | null): APIVerkooporderWithCompany[] {
    if (!customerNumber) {
      return salesorders;
    }
    return salesorders.filter(so => so.customerNumber === customerNumber);
  }
}