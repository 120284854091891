import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FRESHDESK_SERVICE_URL } from 'src/app/injection-tokens';
import { APIVerkooporderWithCompany } from '../../salesorder';
import { DateFilters, ErrorFilters } from '../salesorder-filter/salesorder-filter.pipe';

@Component({
  selector: 'app-salesorder-overview-table',
  templateUrl: './salesorder-overview-table.component.html',
  styleUrls: ['./salesorder-overview-table.component.scss']
})
export class SalesorderOverviewTableComponent implements OnChanges {
  @Input() salesorders!: APIVerkooporderWithCompany[];
  @Input() searchText!: string;
  @Input() selectedDateFilter!: DateFilters;
  @Input() selectedErrorFilter!: ErrorFilters;
  @Input() hideProcessedSalesorders!: boolean;
  @Input() page!: number;
  @Output() selectionChanged = new EventEmitter<number[]>();

  selectedCustomerNumber: string | null = null;
  selectedSalesorderIds: number[] = [];

  constructor(@Inject(FRESHDESK_SERVICE_URL) readonly freshdeskServiceUrl: string) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['searchText'] ||
      changes['selectedDateFilter'] ||
      changes['selectedErrorFilter'] ||
      changes['hideProcessedSalesorders']
    ) {
      this.selectedSalesorderIds = [];
      this.selectedCustomerNumber = null;
      this.selectionChanged.emit([]);
    }
  }

  onCheckboxChange(salesorder: APIVerkooporderWithCompany, event: any): void {
    if (event.target.checked) {
      if (!this.selectedCustomerNumber) {
        this.selectedCustomerNumber = salesorder.customerNumber;
      }
      if (this.selectedCustomerNumber !== salesorder.customerNumber) {
        event.target.checked = false;
        return;
      }
      this.selectedSalesorderIds.push(salesorder.id);
    } else {
      this.selectedSalesorderIds = this.selectedSalesorderIds.filter(id => id !== salesorder.id);
      if (this.selectedSalesorderIds.length === 0) {
        this.selectedCustomerNumber = null;
      }
    }
    this.selectionChanged.emit(this.selectedSalesorderIds);
  }
}
