import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { SALESORDERS_SERVICE_URL, INDI_CUSTOMERS_URL } from 'src/app/injection-tokens';
import { APIVerkooporder, APIVerkooporderWithCompany } from '../salesorder';
import { IndiCustomer } from '../indi-customer';

@Injectable({
  providedIn: 'root'
})
export class SalesorderOverviewService {
  private readonly bulkRequestChunkSize = 80;

  constructor(
    @Inject(SALESORDERS_SERVICE_URL) private readonly salesordersServiceURL: string,
    @Inject(INDI_CUSTOMERS_URL) private readonly indiCustomersUrl: string,
    private readonly http: HttpClient
  ) {}

  getSalesordersWithCompany(): Observable<APIVerkooporderWithCompany[]> {
    return this.getAllSalesorders().pipe(
      mergeMap((orders: APIVerkooporder[]) => {
        const uniqueCustomerNumbers = Array.from(new Set(orders.map((o: APIVerkooporder) => o.customerNumber)));
        return this.getBulkCustomerInfo(uniqueCustomerNumbers).pipe(
          map((customers: IndiCustomer[]) => {
            const lookup = this.buildCustomerLookup(customers);
            return orders.map((order: APIVerkooporder) => this.enrichOrder(order, lookup));
          })
        );
      })
    );
  }

  getAllSalesorders(): Observable<APIVerkooporder[]> {
    return this.http.get<APIVerkooporder[]>(this.salesordersServiceURL);
  }

  getBulkCustomerInfo(customerNumbers: string[]): Observable<IndiCustomer[]> {
    const customerNumberChunks: string[][] = this.splitArray(customerNumbers);

    const customerInfoObservables: Observable<IndiCustomer[]>[] = customerNumberChunks.map((chunk: string[]) => {
      const queryParam = chunk.join('&customerNumber=');
      const customerUrl = `${this.indiCustomersUrl}?customerNumber=${queryParam}`;
      return this.http.get<IndiCustomer[]>(customerUrl);
    });

    return forkJoin(customerInfoObservables).pipe(
      map((results: IndiCustomer[][]) => results.reduce((acc, cur) => acc.concat(cur), []))
    );
  }

  splitArray(arr: string[]): string[][] {
    const result: string[][] = [];
    for (let i = 0; i < arr.length; i += this.bulkRequestChunkSize) {
      result.push(arr.slice(i, i + this.bulkRequestChunkSize));
    }
    return result;
  }

  buildCustomerLookup(customers: IndiCustomer[]): Map<string, IndiCustomer> {
    return customers.reduce((lookup, customer) => 
      lookup.set(customer.customerNumber, customer), new Map<string, IndiCustomer>());
  }
  
  enrichOrder(order: APIVerkooporder, lookup: Map<string, IndiCustomer>): APIVerkooporderWithCompany {
    const customer = lookup.get(order.customerNumber);
    const companyName = customer && customer.companyName ? customer.companyName : '';
    return { ...order, companyName } as APIVerkooporderWithCompany;
  }
}