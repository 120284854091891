import { Pipe, PipeTransform } from '@angular/core';
import { APIVerkooporderWithCompany } from '../salesorder';

@Pipe({
  name: 'salesorderSearch',
})
export class SalesorderSearchPipe implements PipeTransform {
  transform(items: APIVerkooporderWithCompany[], searchText: string): APIVerkooporderWithCompany[] {
    if (!items) return [];

    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((salesorder) => {
      return (
        salesorder.ticketId.toString().includes(searchText) ||
        salesorder.createdAt.toString().toLowerCase().includes(searchText) ||
        salesorder.errorText?.toLowerCase().includes(searchText) ||
        salesorder.customerNumber?.toLowerCase().includes(searchText) ||
        salesorder.companyName?.toLowerCase().includes(searchText) ||
        salesorder.createdOrderNumber?.toLowerCase().includes(searchText) ||
        salesorder.updatedAt?.toString().toLowerCase().includes(searchText) ||
        salesorder.updatedBy?.toString().toLowerCase().includes(searchText)
      );
    });
  }
}
