<div class="d-flex flex-column align-items-center">
  <h3 class="text-center mb-3">Adresgegevens</h3>
  <div class="d-flex flex-column justify-content-center align-items-start w-75">
    <div class="d-flex flex-column mb-2 w-100">
      <label class="form-label" for="company"
        ><strong>Bedrijfsnaam</strong></label
      >
      <input
        id="company"
        type="text"
        class="form-control form-control-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.company'))"
        [formValidity]="form.get('deliveryAddress.company')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="form.get('deliveryAddress.company')?.invalid"
        class="text-danger"
      >
        Bedrijfsnaam is verplicht.
      </div>
    </div>
    <div class="d-flex flex-wrap mb-2 w-100 gap-3">
      <div class="flex-grow-1">
        <label for="firstName" class="form-label">
          <strong>Voornaam</strong>
        </label>
        <input
          id="firstName"
          type="text"
          class="form-control form-control-sm"
          [formControl]="getFormControl(form.get('deliveryAddress.firstName'))"
          [formValidity]="form.get('deliveryAddress.firstName')"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        />
        <div
          *ngIf="form.get('deliveryAddress.firstName')?.invalid"
          class="text-danger"
        >
          Voornaam is verplicht.
        </div>
      </div>
      <div class="flex-grow-1">
        <label for="lastName" class="form-label">
          <strong>Achternaam</strong>
        </label>
        <input
          id="lastName"
          type="text"
          class="form-control form-control-sm"
          [formControl]="getFormControl(form.get('deliveryAddress.lastName'))"
          [formValidity]="form.get('deliveryAddress.lastName')"
          [formValidityType]="'invalid'"
          [formValidityNeedsTouch]=false
        />
        <div
          *ngIf="form.get('deliveryAddress.lastName')?.invalid"
          class="text-danger"
        >
          Achternaam is verplicht.
        </div>
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100">
      <label class="form-label" for="address"><strong>Adres</strong></label>
      <input
        id="address"
        type="text"
        class="form-control form-control-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.address'))"
        [formValidity]="form.get('deliveryAddress.address')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="form.get('deliveryAddress.address')?.invalid"
        class="text-danger"
      >
        Adres is verplicht.
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100">
      <label class="form-label" for="postalCode"
        ><strong>Postcode</strong></label
      >
      <input
        id="postalCode"
        type="text"
        class="form-control form-control-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.postalCode'))"
        [formValidity]="form.get('deliveryAddress.postalCode')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="form.get('deliveryAddress.postalCode')?.invalid"
        class="text-danger"
      >
        Postcode is verplicht.
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100">
      <label class="form-label" for="city"><strong>Plaats</strong></label>
      <input
        id="city"
        type="text"
        class="form-control form-control-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.city'))"
        [formValidity]="form.get('deliveryAddress.city')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="form.get('deliveryAddress.city')?.invalid"
        class="text-danger"
      >
        Plaats is verplicht.
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100">
      <label for="country" class="form-label">
        <strong>Land</strong>
      </label>
      <select
        id="country"
        class="form-select form-select-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.country'))"
        [formValidity]="form.get('deliveryAddress.country')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      >
        <option [value]="''">Selecteer een land</option>
        <option [value]="'NL'">Nederland (NL)</option>
        <option [value]="'DE'">Duitsland (DE)</option>
      </select>
      <div
        *ngIf="form.get('deliveryAddress.country')?.hasError('required')"
        class="text-danger"
      >
        Land is verplicht.
      </div>
      <div
        *ngIf="form.get('deliveryAddress.country')?.hasError('invalidCountry')"
        class="text-danger"
      >
        Land moet Nederland (NL) of Duitsland (DE) zijn.
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100">
      <label class="form-label" for="telephoneNumber"
        ><strong>Telefoonnummer</strong></label
      >
      <input
        id="telephoneNumber"
        type="text"
        class="form-control form-control-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.telephoneNumber'))"
        [formValidity]="form.get('deliveryAddress.telephoneNumber')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="form.get('deliveryAddress.telephoneNumber')?.invalid"
        class="text-danger"
      >
        Telefoonnummer is verplicht.
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100">
      <label class="form-label" for="email" class="form-label"
        ><strong>E-mailadres</strong></label
      >
      <input
        id="email"
        type="text"
        class="form-control form-control-sm"
        [formControl]="getFormControl(form.get('deliveryAddress.email'))"
        [formValidity]="form.get('deliveryAddress.email')"
        [formValidityType]="'invalid'"
        [formValidityNeedsTouch]=false
      />
      <div
        *ngIf="form.get('deliveryAddress.email')?.invalid"
        class="text-danger"
      >
        E-mailadres is verplicht.
      </div>
    </div>
    <div class="d-flex flex-column mb-2 w-100" *ngIf="isNightShippingMethod()">
      <strong>Dit was een order met nightshipping</strong>
      <div class="form-check">
        <input
          id="switchToDayShipping"
          type="checkbox"
          class="form-check-input"
          [formControl]="
            getFormControl(form.get('deliveryAddress.switchToDayShipping'))
          "
          (change)="onSwitchToDayShippingChange()"
        />
        <label for="switchToDayShipping" class="form-check-label">
          <strong>Verzenden met daglevering</strong>
        </label>
      </div>
    </div>
  </div>
</div>
