import { Component, Inject, Input } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BASE_INDI_URL } from 'src/app/injection-tokens';
import { APIOrderline } from '../../salesorder';
import { APIOrderlineToWrite } from '../../salesorderToWrite';

@Component({
  selector: 'app-new-salesorder-orderlines',
  templateUrl: './new-salesorder-orderlines.component.html',
  styleUrls: ['./new-salesorder-orderlines.component.scss'],
})
export class NewSalesorderOrderlinesComponent {
  @Input() orderlines!: FormArray;

  constructor(
    @Inject(BASE_INDI_URL) readonly baseIndiUrl: string,
    private fb: FormBuilder
  ) {}

  createOrderlines(orderlines: APIOrderline[]): FormArray {
    const orderlineFormGroups = orderlines.map((orderline) =>
      this.createOrderlineGroup(orderline)
    );
    return this.fb.array(orderlineFormGroups);
  }

  createOrderlineGroup(orderline: APIOrderline): FormGroup {
    const formGroup = this.fb.group({
      rowNumber: orderline.rowNumber,
      checked: !!!orderline.validationMessage,
      sku: [orderline.item.sku, Validators.required],
      name: [orderline.item.name],
      quantity: [
        orderline.quantity, [
          Validators.required,
          Validators.pattern(/^(0\.\d{1,2}|[1-9]\d{0,3}(\.\d{1,2})?|10000)$/),
        ],
      ],
      orderlineRef: [orderline.orderlineRef],
      validationMessage: [orderline.validationMessage || ''],
    });

    return formGroup;
  }

  addNewOrderline(): void {
    const rowNumbers = this.orderlines.value.map((orderline: APIOrderline) => {
      return orderline.rowNumber;
    })
    const newOrderline: APIOrderline = {item: {}, orderlineRef: ''} as APIOrderline;
    newOrderline.rowNumber = rowNumbers.length === 0 ? 1: Math.max(...rowNumbers) + 1;
    const newOrderlineFormGroup = this.createOrderlineGroup(newOrderline);
    newOrderlineFormGroup.markAllAsTouched()

    this.orderlines.push(newOrderlineFormGroup);
  }

  get hasSelectedOrderlines(): boolean {
    return this.orderlines.value.some((o: any) => {
      return o.checked;
    });
  }

  get selectedOrderlinesAreValid(): boolean {
    const hasInvalidOrderline = this.orderlines.controls.some((o: any) => {
      return !!o.get('checked').value && !!!o.valid;
    });

    return !hasInvalidOrderline;
  }

  get valid(): boolean {
    return this.hasSelectedOrderlines && this.selectedOrderlinesAreValid;
  }

  getOrderlines(): APIOrderlineToWrite[] {
    return this.orderlines.getRawValue()
      .filter((orderline) => orderline.checked)
      .map((orderline) => {
        return {
          rowNumber: orderline.rowNumber,
          item: {
            sku: orderline.sku,
          },
          quantity: orderline.quantity,
          orderlineRef: orderline.orderlineRef,
        };
      });
  }

  selectAllOrderlines(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.orderlines.controls.forEach(control => {
      control.get('checked')?.setValue(isChecked);
    });
  }

  get allOrderlinesSelected(): boolean {
    return this.orderlines.controls.length > 0 && this.orderlines.controls.every(
      control => control.get('checked')?.value
    );
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
