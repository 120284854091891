<div *ngFor="let delivery of orderline.deliveryStatus.deliveries">
  <div>
    <i class="fa fa-check green"></i>
    ({{ delivery.quantity }}) Verstuurd op
    {{ delivery.deliveryDate.toString() | date : "dd/MM/yyyy" }}
  </div>
</div>
<div *ngIf="orderline.deliveryStatus.quantityInProcess > 0">
  <i class="fa fa-hourglass dark"></i>
  ({{ orderline.deliveryStatus.quantityInProcess }}) In behandeling
</div>
<div
  *ngFor="let expectedDelivery of orderline.deliveryStatus.expectedDeliveries"
>
  <i
    class="fa"
    [class.fa-calendar-xmark]="IsDateUnknown(expectedDelivery.deliveryDate)"
    [class.fa-calendar-day]="!IsDateUnknown(expectedDelivery.deliveryDate)"
  ></i>
  ({{ expectedDelivery.quantity }}) Leverdatum
  {{
    IsDateUnknown(expectedDelivery.deliveryDate)
      ? "onbekend"
      : (expectedDelivery.deliveryDate | date : "dd/MM/yyyy")
  }}
</div>
<div *ngIf="orderline.quantityReturned > 0">
  <i class="fa fa-reply"></i>
  ({{ orderline.quantityReturned }}) Geretourneerd
</div>
<div *ngIf="orderline.quantityCancelled > 0">
  <i class="fa fa-times-circle red"></i>
  ({{ orderline.quantityCancelled }}) Geannuleerd
</div>
