import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SHIPPING_METHOD_CODES } from '../shipping-methods.const';
import { Address } from '../../indi-customer';
import { AddressToWrite } from '../../salesorderToWrite';

@Component({
  selector: 'app-new-salesorder-shipping-details',
  templateUrl: './new-salesorder-shipping-details.component.html',
  styleUrls: ['./new-salesorder-shipping-details.component.scss'],
})
export class NewSalesorderShippingDetailsComponent implements OnInit {
  readonly AVAILABLE_SHIPPING_METHODS: string[] = Object.keys(SHIPPING_METHOD_CODES);
  readonly COUNTRY_PATTERN: RegExp = /^(NL|DE)$/;

  @Input() shippingDetails!: FormGroup;
  @Input() deliveryAddress!: Address;
  @Input() addresses: Address[] = [];
  @Input() nightShippingAddress?: Address;
  @Input() hasNightShipping: boolean = false;
  @Input() hasPickUpAtCounter: boolean = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    // Initializing this as empty to prevent errors
    if (!this.shippingDetails.get('shippingMethod')) {
      this.shippingDetails = this.fb.group({
        shippingMethod: ['', Validators.required],
      });
    }
  }

  initializeAddressData(): void {
    setTimeout(() => {
      if (this.hasNightShipping) {
        this.shippingDetails.patchValue({'shippingMethod': 'Nachtbezorging'})
      } else if (this.hasPickUpAtCounter) {
        this.shippingDetails.patchValue({'shippingMethod': 'Afhalen bij de balie'})
      } else {
        this.shippingDetails.patchValue({'shippingMethod': 'Dagbezorging'})
      }
      this.shippingDetails.get('shippingMethod')?.valueChanges.subscribe(() => {
        this.setDeliveryAddressFormValues(0);
      });
      this.setDeliveryAddressFormValues(0);
    }, 0);
  }

  setDeliveryAddressFormValues(index: number): void {
    const addressGroup = this.shippingDetails.get('deliveryAddress') as FormGroup;
    const shippingMethod = this.shippingDetails.get('shippingMethod')?.value
    const defaultShippingAddress = this.addresses[0];
    if (addressGroup) {
      switch (shippingMethod) {
        case 'Dagbezorging':
          this.setDeliveryAddressValues(this.addresses[index]);
          break;
        case 'Nachtbezorging':
          this.setDeliveryAddressValues(this.nightShippingAddress!);
          break;
        case 'Ander adres kiezen':
          this.setDeliveryAddressValues(this.deliveryAddress);
          break;
        case 'Afhalen bij de balie':
          this.setDeliveryAddressValues(defaultShippingAddress);
          break;
        default:
          break;
      }
      addressGroup.updateValueAndValidity({ emitEvent: false });
    }
  }

  setDeliveryAddressValues(deliveryAddress: Address): void {
    this.shippingDetails.get('deliveryAddress')?.patchValue({
      companyName: deliveryAddress.companyName,
      firstName: deliveryAddress.firstName,
      lastName: deliveryAddress.lastName,
      address: deliveryAddress.address,
      postalCode: deliveryAddress.postalCode,
      city: deliveryAddress.city,
      countryCode: deliveryAddress.countryCode ?? '',
    });
  }

  createShippingDetails(deliveryAddress: Address): FormGroup {
    return this.fb.group({
      shippingMethod: '',
      deliveryAddress: this.createAddressGroup(deliveryAddress),
    });
  }

  createAddressGroup(deliveryAddress: Address): FormGroup {
    return this.fb.group({
      companyName: [deliveryAddress.companyName, Validators.required],
      firstName: [deliveryAddress.firstName, Validators.required],
      lastName: [deliveryAddress.lastName, Validators.required],
      address: [deliveryAddress.address, Validators.required],
      postalCode: [deliveryAddress.postalCode, Validators.required],
      city: [deliveryAddress.city, Validators.required],
      countryCode: [
        deliveryAddress.countryCode ?? '',
        [Validators.required, Validators.pattern(this.COUNTRY_PATTERN)],
      ],
      email: [
        '',
        [Validators.required, Validators.email],
      ],
    });
  }

  get valid(): boolean {
    return this.shippingDetails.valid;
  }

  getDeliveryAddress(): AddressToWrite {
    return this.shippingDetails.getRawValue().deliveryAddress;
  }

  getShippingMethod(): string {
    return this.shippingDetails.getRawValue().shippingMethod;
  }

  toFormControl(control: AbstractControl | null): FormControl {
    return control as FormControl;
  }
}
