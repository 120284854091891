import { Component, EventEmitter, Output } from '@angular/core';
import { DateFilters, ErrorFilters } from './salesorder-filter.pipe';

@Component({
  selector: 'app-salesorder-filter',
  templateUrl: './salesorder-filter.component.html',
  styleUrls: ['./salesorder-filter.component.scss'],
})
export class SalesorderFilterComponent {
  possibleDateFilters = Object.values(DateFilters);
  selectedDateFilter: DateFilters = DateFilters.ThreeDays;
  possibleErrorFilters = Object.values(ErrorFilters);
  selectedErrorFilter: ErrorFilters = ErrorFilters.Both;
  filterHideProcessedSalesordersChange: boolean = false;

  @Output() selectedDateFilterChange = new EventEmitter<DateFilters>();
  @Output() selectedErrorFilterChange = new EventEmitter<ErrorFilters>();
  @Output() hideProcessedSalesordersChange = new EventEmitter<boolean>();

  onSelectedDateFilterChange(): void {
    this.selectedDateFilterChange.emit(this.selectedDateFilter);
  }

  onSelectedErrorFilterChange(): void {
    this.selectedErrorFilterChange.emit(this.selectedErrorFilter);
  }

  onHideProcessedSalesordersChange(): void {
    this.hideProcessedSalesordersChange.emit(
      this.filterHideProcessedSalesordersChange
    );
  }
}
