import { addDays, format } from 'date-fns';
import { Pipe, PipeTransform } from '@angular/core';
import { APIVerkooporderWithCompany } from '../../salesorder';

export enum DateFilters {
  OneDay = "Een dag",
  TwoDays = "Twee dagen",
  ThreeDays = "Drie dagen",
}

export enum ErrorFilters {
  OnlyErrors = "Foute orders",
  NoErrors = "Goede orders",
  Both = "Beide",
}

@Pipe({
  name: 'salesorderFilter',
})
export class SalesorderFilterPipe implements PipeTransform {
  transform(
    salesorders: APIVerkooporderWithCompany[],
    dateFilter: DateFilters,
    errorFilter: ErrorFilters,
    hideProcessedSalesorders: boolean
  ): APIVerkooporderWithCompany[] {
    const newDate = format(addDays(this.today(), -3), 'yyyy-MM-dd');

    let lowerBound: string;
    switch (dateFilter) {
      case DateFilters.OneDay:
        lowerBound = format(addDays(this.today(), -1), 'yyyy-MM-dd');
        break;
      case DateFilters.TwoDays:
        lowerBound = format(addDays(this.today(), -2), 'yyyy-MM-dd');
        break;
      case DateFilters.ThreeDays:
      default:
        lowerBound = newDate;
        break;
    }

    return salesorders.filter((salesorder: APIVerkooporderWithCompany) => {
      if (hideProcessedSalesorders && salesorder.createdOrderNumber) {
        return false;
      }

      if (errorFilter == ErrorFilters.OnlyErrors && !!!salesorder.errorText) {
        return false;
      }

      if (errorFilter == ErrorFilters.NoErrors && !!salesorder.errorText) {
        return false;
      }

      return salesorder.createdAt.toLowerCase().split('t')[0] >= lowerBound;
    });
  }

  today(): Date {
    return new Date();
  }
}
